.nav-burger {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.nav-burger-line {
    width: 40px;
    height: 3px;
    background-color: var(--quill-color);
}

.nav-burger-one {
    margin-bottom: 12px;
}

.nav-burger-three {
    margin-top: 12px;
}

.nav-burger-one,
.nav-burger-three {
    transform-origin: 100%;
    transition: transform 400ms ease-in-out;
    transform: translateY(0) rotate(0deg);
}

.nav-burger-one-open {
    transform: rotate(-45deg) translateY(-9px);
    transition: transform 400ms ease-in-out;
}

.nav-burger-two-open {
    display: none;
}

.nav-burger-three-open {
    transform: rotate(45deg) translateY(10px);
    transition: transform 400ms ease-in-out;
}
