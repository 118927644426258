.editor-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 2px;
}
