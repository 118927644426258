.character-info-card-image {
    position: relative;
}

.character-info-card-info {
    padding: 5px;
    margin: 5px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: var(--background);
    transition: opacity 500msease-out 100ms;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
}

.character-info-card-info:hover {
    opacity: 0.9;
    font-family: 'Ubuntu', sans-serif;
}
