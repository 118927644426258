.quill-custom .ql-toolbar,
.quill-custom .ql-container {
    border: 1px solid #dadada;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
}

.quill-custom .ql-toolbar {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.quill-custom .ql-container {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 10px;
    height: 400px;
}
