@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

.light {
    --placeholder: #333;
    --background: #fff;
    --secondary: #4d98cf;
    --quill-color: #333;
    --button-spinner: #fff;
}

.dark {
    --placeholder: #fafafa;
    --background: #000;
    --secondary: #58a9e4;
    --quill-color: #fafafa;
    --button-spinner: #fff;
}

.mint {
    --placeholder: #1a7136;
    --background: #fff;
    --secondary: #26a44f;
    --quill-color: #333;
    --button-spinner: #fff;
}

.fall {
    --placeholder: #5a3c28;
    --background: #fff;
    --secondary: #e36414;
    --quill-color: #333;
    --button-spinner: #fff;
}

.halloween {
    --placeholder: #fefae0;
    --background: #000;
    --secondary: #e36414;
    --quill-color: #fafafa;
    --button-spinner: #fff;
}

.orchid {
    --placeholder: #f9f0e0;
    --background: #231e29;
    --secondary: #e7d1fc;
    --quill-color: #fafafa;
    --button-spinner: #752d70;
}

.midnight {
    --placeholder: #9895cf;
    --background: #040622;
    --secondary: #6865ab;
    --quill-color: #f0f1ff;
    --button-spinner: #4e4a93;
}

.forest {
    --placeholder: #96cf95;
    --background: #062204;
    --secondary: #6bab65;
    --quill-color: #f0f1ff;
    --button-spinner: #50934a;
}

html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
}

#reactgooglegraph-1 {
    font-family: 'Ubuntu' !important;
}

.mui-checkbox span {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: var(--secondary);
}

.filter-group {
    background-color: var(--background);
}

.filter-group-inactive {
    color: var(--quill-color);
}

.filter-group-active {
    color: var(--secondary);
}

main {
    min-height: calc(100vh - 268px);
}

h1 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 20px 0;
}

h2 {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 15px 0;
}

h3 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
}

input,
select,
textarea {
    background-color: var(----main-background);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
}

.status-filter {
    cursor: pointer;
    accent-color: var(--secondary);
}

input::placeholder {
    color: var(--placeholder);
    opacity: 0.6;
}

/* Text Editor */

.quill {
    background-color: var(--background);
    color: var(--quill-color);
}

.ql-formats svg {
    filter: invert(80%);
}

.trash-icon:hover,
.edit-icon:hover,
.graph-icon:hover {
    filter: opacity(50%);
}

/* Loader */

.lds-ring {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid var(--secondary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--secondary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

.lds-ring-button {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}
.lds-ring-button div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 4px;
    border: 4px solid var(--button-spinner);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--button-spinner) transparent transparent transparent;
}
.lds-ring-button div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring-button div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring-button div:nth-child(3) {
    animation-delay: -0.15s;
}

/* Status spinner */

.status-spinner {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
}
.status-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 3px;
    border: 3px solid var(--secondary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--secondary) transparent transparent transparent;
}
.status-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.status-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.status-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

.dark input[type='date'],
.halloween input[type='date'],
.orchid input[type='date'],
.midnight input[type='date'],
.forest input[type='date'] {
    color-scheme: dark;
}

.tiptap {
    background-color: var(----main-background);
    color: var(--quill-color);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    height: 380px;
    overflow: auto;
}

.tiptap:focus-visible {
    outline: none;
}

.w-color-compact {
    width: 205px !important;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
